
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  watch,
  onMounted,
  inject,
  computed,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import Tabs from "@/components/atomics/Tabs/Tabs.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import LoadMore from "@/components/atomics/LoadMore.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Popup from "@/components/popups/Popup.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import {
  MemberRepository,
  GroupRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useStore } from "vuex";
import moment from "moment";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

enum memberRole {
  ADMIN,
  OWNER,
  MANAGER,
  MEMBER,
}

export default defineComponent({
  name: "TeamMember",
  components: {
    AfterLoginPageLayout,
    MenuOptionPopup,
    Tabs,
    FlatButton,
    TextButton,
    LoadMore,
    ValidationTextField,
    Popup,
    TableSelect,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    deleteItemSuccess: { type: String, default: "" },
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const { t } = useI18n();
    const store = useStore();
    const state = reactive({
      isOverflow: false,
      isAuthorized: true,
      showMenuOptionIndex: -1,
      popupTitle: "",
      popupMessage: "",
      targetMember: ref(),
      memberType: 0,
      showAddPopupIndex: 0,
      memberRows: [{ email: "", name: "" }],
      selectedGroups: [],
      currentTabIndex: 0,
      tabTags: [
        { name: t("teamMember.member"), total: 0 },
        { name: t("teamMember.invited"), total: 0 },
      ],
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      inviteMembers: [],
      memberPermissions: {
        role: memberRole.MEMBER,
        canCreate: false,
        bookmark: 0,
      },
      inviteMemberPermissions: {
        role: memberRole.MEMBER,
        canCreate: false,
        bookmark: 0
      },
      accountInfor: { id: "", name: "", role: 1, avatar: "" },
      haveDivider: false,
    });

    const members = ref();
    const newMemberData = ref();

    const {
      getMemberList,
      getInviteMemberList,
      resetMember2FA,
      blockMember,
      unblockMember,
      deleteMember,
      pendingResend,
      pendingCancel,
      addMember,
    } = memberAction();

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const { getSimpleGroupList } = groupMethods();

    const groups = ref();

    const submitSuccess = async () => {
      state.showAddPopupIndex = -1;
      const inviteMemberPayload = await getInviteMemberList(
        1,
        PAGE_SIZE_DEFAULT,
        ""
      );
      state.inviteMembers = inviteMemberPayload.data;
      state.tabTags[1].total = inviteMemberPayload.pagination.totalRecords || 0;
    };

    const form = useForm();
    const { submit } = formHandler(newMemberData, addMember, submitSuccess);

    const submitMember = () => {
      newMemberData.value = {
        members: state.memberRows,
        role: state.memberType,
        groups: state.selectedGroups,
      };
      submit().then(() => {
        totalInvitedMember.value++;
      }).catch((e) => {
        showErrorNotification(e.data.message);
        state.showAddPopupIndex = 0;
      });
    };

    const { formHandle } = useFormHandler(form, async () => {
      state.showAddPopupIndex++;
    });

    const nextPopup = async function () {
      await formHandle();
    };

    const searchKeywordMember = ref("");
    const searchKeywordInvitedMember = ref("");
    const searchKeywordMemberTextField = ref("");
    const searchKeywordInvitedMemberTextField = ref("");
    let delay: ReturnType<typeof setTimeout>;

    let page = 1;
    let totalMember = ref(0);
    let totalInvitedMember = ref(0);
    let isLoading = false;

    watch(
      () => totalMember.value,
      (val) => {
        state.tabTags[0].total = val;
      }
    );

    watch(
      () => totalInvitedMember.value,
      (val) => {
        state.tabTags[1].total = val;
      }
    );

    const PAGE_SIZE_DEFAULT = 20;

    const loadMoreMembers = async (type: string, memberList: any = [], onScroll: boolean) => {
      const currentPage = Math.ceil(memberList.length / PAGE_SIZE_DEFAULT);
      const isNextPage = memberList.length % PAGE_SIZE_DEFAULT === 0;
      if (isNextPage) page = currentPage + 1;
      else page = currentPage;

      if (!isLoading) {
        isLoading = true;
        if (type === "member") {
          const moreGroups = await getMemberList(
            page,
            PAGE_SIZE_DEFAULT,
            searchKeywordMember.value,
            true,
            state.memberPermissions.bookmark as any
          );
          if (moreGroups.pagination.totalRecords > totalMember.value) {
            totalMember.value = moreGroups.pagination.totalRecords;
          } else if (
            moreGroups.data.length % PAGE_SIZE_DEFAULT !== 0 &&
            memberList.length >= moreGroups.pagination.totalRecords && !onScroll
          )
            showErrorNotification(t("errors.errorNoNewRecord"));
          for (const index in moreGroups.data) {
            const isContain = memberList.some(
              (member: any) => member.id === moreGroups.data[index].id
            );
            if (!isContain) memberList.push(moreGroups.data[index]);
          }

          state.memberPermissions.bookmark = moreGroups.pagination.bookmark || 0;

        } else {
          const moreGroups = await getInviteMemberList(
            page,
            PAGE_SIZE_DEFAULT,
            searchKeywordInvitedMember.value,
            state.inviteMemberPermissions.bookmark as any
          );
          if (moreGroups.pagination.totalRecords > totalInvitedMember.value) {
            totalInvitedMember.value = moreGroups.pagination.totalRecords;
          } else if (
            moreGroups.data.length % PAGE_SIZE_DEFAULT !== 0 &&
            memberList.length >= moreGroups.pagination.totalRecords && !onScroll
          )
            showErrorNotification(t("errors.errorNoNewRecord"));

          for (const index in moreGroups.data) {
            const isContain = memberList.some(
              (member: any) => member.id === moreGroups.data[index].id
            );
            if (!isContain) memberList.push(moreGroups.data[index]);
          }
        
          state.inviteMemberPermissions.bookmark = moreGroups.pagination.bookmark || 0;
        }

        isLoading = false;
      }
    };
    const handleLoadMore = async (onScroll: boolean) => {
      if (state.currentTabIndex === 0)
        await loadMoreMembers("member", members.value, onScroll);
      else await loadMoreMembers("invitedMember", state.inviteMembers, onScroll);
    };

    // const canLoadMore = computed(() => {
    //   return (
    //     (state.currentTabIndex === 0 &&
    //       (members.value || []).length < totalMember.value) ||
    //     (state.currentTabIndex !== 0 &&
    //       state.inviteMembers.length < totalInvitedMember.value)
    //   );
    // });

    const memberSearch = (keyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        searchKeywordMember.value = keyword;
        const payload = await getMemberList(
          (page = 1),
          PAGE_SIZE_DEFAULT,
          searchKeywordMember.value,
          searchKeywordMember.value.trim() === "" ? true : false
        );
        members.value = payload.data;
        totalMember.value = payload.pagination.totalRecords;
        state.memberPermissions.bookmark = payload.pagination.bookmark || 0
      }, 300);
    };

    const invitedMemberSearch = (keyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        searchKeywordInvitedMember.value = keyword;
        const payload = await getInviteMemberList(
          (page = 1),
          PAGE_SIZE_DEFAULT,
          searchKeywordInvitedMember.value
        );
        state.inviteMembers = payload.data;
        totalInvitedMember.value = payload.pagination.totalRecords;
        state.inviteMemberPermissions.bookmark = payload.pagination.bookmark || 0
      }, 300);
    };

    const clearSearch = () => {
      if (state.currentTabIndex == 0 && searchKeywordMember.value != "") {
        searchKeywordMember.value = "";
        searchKeywordMemberTextField.value = "";
        memberSearch("");
      } else if (
        state.currentTabIndex == 1 &&
        searchKeywordInvitedMember.value != ""
      ) {
        searchKeywordInvitedMember.value = "";
        searchKeywordInvitedMemberTextField.value = "";
        invitedMemberSearch("");
      } else {
        return;
      }
    };

    const isContainEmail = (email: string, index: number) => {
      const emailList = [] as string[];
      state.memberRows.forEach((row: any) => emailList.push(row.email));
      if (emailList.filter((val: string) => val === email).length >= 2) {
        form.setFieldError("email" + index, t("errors.existEmail"));
      } else form.setFieldError("email" + index, "");
    };

    // Reset member 2FA
    const reset2FA = async (member: any) => {
      const result = await resetMember2FA(member.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          const index = members.value.findIndex(
            (element: any) => element.id == member.id
          );
          members.value[index].canReset2FA = false;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Block member
    const blockAction = async (member: any) => {
      const result = await blockMember(member.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          state.targetMember.blocked = true;
          state.targetMember.canUnblock = true;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Unblock member
    const unblockAction = async (member: any) => {
      const result = await unblockMember(member.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          state.targetMember.blocked = false;
          state.targetMember.canBlock = true;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Delete member
    const deleteAction = async (member: any) => {
      const result = await deleteMember(member.id)
        .then((result) => {
          if (result) {
            showSuccessNotification(t("notification.deleteSuccess"));
            members.value.splice(members.value.indexOf(member), 1);
            state.tabTags[0].total = state.tabTags[0].total - 1;
            totalMember.value--;
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Pending resend
    const resendAction = async (inviteMember: any) => {
      const result = await pendingResend(inviteMember.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Pending cancel
    const cancelAction = async (inviteMember: any) => {
      await pendingCancel(inviteMember.id)
        .then((result) => {
          if (result) {
            state.inviteMembers.splice(
              state.inviteMembers.indexOf(inviteMember as never),
              1
            );
            state.tabTags[1].total--;
            totalInvitedMember.value--;
            showSuccessNotification(t("notification.updateSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    const groupSearch = (searchKeyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const groupPayload = await getSimpleGroupList(
          1,
          PAGE_SIZE_DEFAULT,
          searchKeyword
        );
        groups.value = groupPayload.data;
      }, 300);
    };

    onBeforeMount(async () => {
      const memberPayload = await getMemberList(1, PAGE_SIZE_DEFAULT, "", true);
      const inviteMemberPayload = await getInviteMemberList(
        1,
        PAGE_SIZE_DEFAULT,
        ""
      );
      members.value = memberPayload.data;
      state.memberPermissions = memberPayload.pagination;
      totalMember.value = memberPayload.pagination.totalRecords;
      state.inviteMembers = inviteMemberPayload.data;
      state.inviteMemberPermissions = inviteMemberPayload.pagination;
      totalInvitedMember.value = inviteMemberPayload.pagination.totalRecords;
      const groupPayload = await getSimpleGroupList();
      groups.value = groupPayload.data;
    });

    onMounted(() => {
      const accountInfor = store.getters["header/getAccountInfo"];
      state.accountInfor = accountInfor;
      if (props.deleteItemSuccess != "") {
        showSuccessNotification(props.deleteItemSuccess);
      }
    });

    return {
      ...toRefs(state),
      members,
      reset2FA,
      blockAction,
      unblockAction,
      deleteAction,
      resendAction,
      cancelAction,
      isLoading,
      totalMember,
      totalInvitedMember,
      memberSearch,
      invitedMemberSearch,
      searchKeywordMember,
      searchKeywordInvitedMember,
      searchKeywordMemberTextField,
      searchKeywordInvitedMemberTextField,
      groupSearch,
      memberRole,
      groups,
      submitMember,
      nextPopup,
      form,
      getFilePathFromUrl,
      clearSearch,
      isMobile,
      handleLoadMore,
      isContainEmail,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      const height = window.innerHeight;
      const clientHeight = this.memberRows.length * (this.isMobile ? 50 : 60);
      if (clientHeight > height - 300) {
        this.haveDivider = true;
      } else {
        this.haveDivider = false;
      }
      this.onUpdateLayoutTableSelect();
    });
  },
  deactivated() {
    window.removeEventListener("resize", this.onUpdateLayoutTableSelect);
  },
  methods: {
    showDetail(
      id: string,
      canUpdate: boolean,
      canDelete: boolean,
      isInvitingMember: any
    ) {
      this.$router.push({
        name: "MemberProfile",
        query: { isInvitingMember: isInvitingMember },
        params: {
          userID: id,
          canUpdate: canUpdate.toString(),
          canDelete: canDelete.toString(),
        },
      });
    },

    navigationToAccount(): void {
      this.$router.push("/account");
    },

    showMenuOptions(index: number) {
      this.showMenuOptionIndex = index;
    },

    hideMenuOption(e: any): void {
      if (e.target.className != "icon selected" && e.target.alt != "more") {
        this.showMenuOptionIndex = -1;
      }
    },

    showPopup(title: string, message: string, member: any) {
      this.popupTitle = title;
      this.popupMessage = message;
      this.targetMember = member;
    },

    hidePopup() {
      this.popupTitle = "";
      this.popupMessage = "";
    },

    hideAddPopup() {
      this.showAddPopupIndex = 0;
      this.memberType = 0;
      this.haveDivider = false;
    },

    onChooseTypeMember(memberType: number) {
      if (this.memberPermissions.role <= memberType) {
        this.memberType = memberType;
        this.showAddPopupIndex = 2;
      }
    },

    addMoreRow() {
      this.memberRows.push({ email: "", name: "" });
      const height = window.innerHeight;
      const clientHeight = this.memberRows.length * (this.isMobile ? 50 : 60);
      if (clientHeight > height - 300) {
        this.haveDivider = true;
      }
    },

    removeRow(index: number) {
      if (this.memberRows.length > 1) this.memberRows.splice(index, 1);
      const height = window.innerHeight;
      const clientHeight = this.memberRows.length * (this.isMobile ? 50 : 60);
      if (clientHeight <= height - 300) {
        this.haveDivider = false;
      }
    },

    backPopup() {
      if (this.showAddPopupIndex == 2) {
        this.memberType = 0;
      }
      this.showAddPopupIndex--;
    },

    updateSelected(payload: any): void {
      this.selectedGroups = payload;
    },

    onUpdateLayoutTableSelect() {
      const height = window.innerHeight;
      const popupContainer = this.$refs.popup as HTMLElement;
      const clientHieght = popupContainer?.clientHeight as number;
      if (clientHieght >= height - 250) {
        this.isOverflow = true;
      } else {
        this.isOverflow = false;
      }
    },

    checkEmpty() {
      let enable = false;
      this.memberRows.forEach((element) => {
        if (element.name == "" || element.email == "") {
          enable = true;
        }
      });
      return enable;
    },

    showPopupAddMember() {
      this.showAddPopupIndex = 1;
      this.memberRows = [{ email: "", name: "" }];
      this.form.resetForm();
    },

    onMousedown(e: any): void {
      e.preventDefault();
    },
  },
});

// Add new members
const formHandler = (
  newMemberInfo: any,
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const submit = async () => {
    const memberList = [] as never[];
    newMemberInfo.value.members.forEach((element: any) => {
      let info = {
        email: element.email,
        name: element.name,
      };
      memberList.push(info as never);
    });

    const groupList = [] as never[];
    newMemberInfo.value.groups.forEach((element: any) => {
      groupList.push(element.id as never);
    });

    const data = {
      members: memberList,
      role: newMemberInfo.value.role,
      groups: groupList,
    };

    if (await onSubmit(data)) {
      onSuccess();
    }
  };

  return { submit };
};

// Member Actions
const memberAction = () => {
  const {
    getMemberList,
    getInviteMemberList,
    resetMember2FA,
    blockMember,
    unblockMember,
    deleteMember,
    pendingResend,
    pendingCancel,
    addMember,
  } = RepositoryFactory.getRepository<MemberRepository>(MemberRepository);
  return {
    getMemberList,
    getInviteMemberList,
    resetMember2FA,
    blockMember,
    unblockMember,
    deleteMember,
    pendingResend,
    pendingCancel,
    addMember,
  };
};

// Get group list
const groupMethods = () => {
  const { getSimpleGroupList } =
    RepositoryFactory.getRepository<GroupRepository>(GroupRepository);
  return {
    getSimpleGroupList,
  };
};
